import {View} from 'react-native';
import tableStyle from '../style';
import React from 'react';
import Text from '@/components/basic/text';
import theme from '@/style';
import {BasicObject} from '@/types';
import {toPriceStr} from '@/utils';
import {useTranslation} from 'react-i18next';

const gameType = [
  '',
  'AnkOpen',
  'AnkClose',
  'Jodi',
  'SPOpen',
  'SPColse',
  'DPOpen',
  'DPClose',
  'TPOpen',
  'TPClose',
  'HSAOpen',
  'HSAClose',
  'FS',
];

const SattaTable = ({list = []}: {list: BasicObject[]}) => {
  const {i18n} = useTranslation();
  return (
    <>
      <View style={[tableStyle.th]}>
        <View style={[theme.flex.flex1]}>
          <Text white>{i18n.t('bets-detail.label.number').toUpperCase()}</Text>
        </View>
        <View style={[tableStyle.tbPayment]}>
          <Text white style={[theme.font.center]}>
            {i18n.t('bets-detail.label.payment').toUpperCase()}
          </Text>
        </View>
        <View style={[theme.flex.flex1]}>
          <Text white style={[tableStyle.textRight]}>
            {i18n.t('bets-detail.label.result').toUpperCase()}
          </Text>
        </View>
      </View>
      <View>
        {list.map((item, index) => (
          <View
            key={index}
            style={[tableStyle.td, index % 2 === 1 && tableStyle.tdGray]}>
            <View style={[theme.flex.flex1, theme.flex.row]}>
              <View>
                <Text white size="medium">
                  {gameType[item.gameType]}
                </Text>
                <Text white size="medium" blod>
                  {item.digits}
                </Text>
              </View>
            </View>
            <View style={[tableStyle.tbPayment]}>
              <Text
                blod
                white
                fontFamily="fontInter"
                size="medium"
                style={[theme.font.center]}>
                {toPriceStr(item.points, {
                  fixed: 2,
                  showCurrency: true,
                  thousands: true,
                })}
              </Text>
            </View>
            <View style={[theme.flex.flex1]}>
              <View
                style={[
                  theme.flex.end,
                  theme.flex.row,
                  theme.flex.centerByCol,
                ]}>
                <View style={[theme.margin.leftxxs, theme.flex.alignEnd]}>
                  <Text white>
                    {i18n.t(
                      item.digitsWonAmount > 0
                        ? 'bets-detail.label.won'
                        : 'bets-detail.label.noWin',
                    )}
                  </Text>
                  <Text
                    color={
                      item.digitsWonAmount > 0
                        ? theme.fontColor.winColor
                        : theme.fontColor.white
                    }
                    fontFamily="fontInter"
                    blod
                    size="medium">
                    {toPriceStr(item.digitsWonAmount || 0, {
                      fixed: 2,
                      showCurrency: true,
                      thousands: true,
                    })}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};

export default SattaTable;
